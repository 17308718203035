import { useState, useEffect, useContext} from "react";
import {
  GridFilterOperator,
  GridFilterItem,
  GridFilterInputValueProps,
} from "@mui/x-data-grid-pro";
import Select, {SelectChangeEvent} from "@mui/material/Select";

import {
  attributesList,
  attributeHierarchyList,
  flattenTree,
} from "../../utils/dataUtils";
import { Checkbox, ListItemText } from '@mui/material';
import {MenuItem, Box} from "@mui/material";
import {
  AttributeContextType,
  AttributeContext,
} from "../../context/attributeContextProvider";

//import RangeFilter from "../../alerts/rangeFilter";
import DateRangeFilter from "../../alerts/dateRangeFilter";
import RangeFilter from "../../alerts/rangeFilter";
import BeforeAfterFilter from "../../alerts/beforeAfterFilter";
import { Label } from "@mui/icons-material";

const AttributeInput = (props: GridFilterInputValueProps) => {
  const {attributeTree} = useContext(AttributeContext) as AttributeContextType;
  const [options, setOptions] = useState<string[]>();
  const {item, applyValue} = props;

  const treeIndex = Object.keys(attributesList).includes(item.columnField)
    ? attributesList[item.columnField]
    : Object.keys(attributeHierarchyList).includes(item.columnField)
    ? attributeHierarchyList[item.columnField]
    : false;

  const handleChange = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: {value},
    } = event;
    setOptions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    applyValue({...item, value});
  };

  return (
    <Box sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center', height: 63, width: 180, pl: '20px' }}>
      <Select
        multiple
        value={item.value || []}
        onChange={handleChange}
      >
        {Object.keys(attributeTree).length > 0 &&
          item?.columnField &&
          treeIndex &&
          Object.keys(
            flattenTree({
              treeArr: attributeTree[treeIndex],
            })
          )
            .sort()
            .map((attribute: any, index: number) => (
              <MenuItem key={index} value={attribute}>
                {attribute}
              </MenuItem>
            ))}
      </Select>
    </Box>
  );
};

// Define the option type with label and value
interface Option {
  label: string;
  value: string | null;
  array?: string[];
}

const LinearChannelDropdown = (props: GridFilterInputValueProps) => {
  // Hardcoded list of linear channels with additional options for Null
  const linearChannels: Option[] = [
    { label: "Don't Tell The Bride", value: "Don't Tell The Bride" },
    { label: "History Hit", value: "History Hit" },
    { label: "Not Needed", value: "Not Needed" },
    { label: "Real Crime Alpha", value: "Real Crime Alpha" },
    { label: "Real Crime Beta", value: "Real Crime Beta" },
    { label: "Real Crime Gamma", value: "Real Crime Gamma" },
    { label: "Real Crime WW", value: "Real Crime WW" },
    { label: "Real Life", value: "Real Life" },
    { label: "Real Stories", value: "Real Stories" },
    { label: "Real Wild", value: "Real Wild" },
    { label: "The Chat Show Channel", value: "The Chat Show Channel" },
    { label: "Wonder", value: "Wonder" },
    // Null option instead of Empty
    { label: "Empty", value: null },
  ];

  const { item, applyValue } = props;

  const [selectedChannels, setSelectedChannels] = useState<(string | null)[]>([]);

  useEffect(() => {
    if (item.value && Array.isArray(item.value)) {
      setSelectedChannels(item.value);
    } else {
      setSelectedChannels([]);
    }
  }, [item.value]);

  // Handle change with explicit typing and type guarding
  const handleChange = (event: SelectChangeEvent<(string | null)[]>) => {
    const { target: { value } } = event;

    // Cast value to string[] | null[] explicitly to avoid type mismatch
    const selectedValues: (string | null)[] = Array.isArray(value) ? value : [value];
    
    setSelectedChannels(selectedValues);
    applyValue({ ...item, value: selectedValues });
  };

  return (
    <Box sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center', height: 63, width: 180, pl: '20px' }}>
      <Select
        labelId="linear-channel-label"
        id="linear-channel-dropdown"
        multiple
        displayEmpty
        value={selectedChannels}
        onChange={handleChange}
        renderValue={(selected) => {
          if ((selected as (string | null)[]).length === 0) {
            return <em>Select Channels</em>;
          }

          // Handle case for "Null" value
          if ((selected as (string | null)[]).includes(null)) {
            return <em>Null</em>;  // Show "Null" when null is selected
          }

          // Default rendering if there are selected channels
          return (selected as string[]).join(", ");
        }}
        style={{ width: '100%' }}
      >
        {linearChannels.map((channel: Option) => (
          <MenuItem key={channel.value ?? ""} value={channel.value as string}> {/* Type cast value as string */}
            <Checkbox checked={selectedChannels.includes(channel.value)} />
            <ListItemText primary={channel.label} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default LinearChannelDropdown;
  const linearChannelSelectOperator: GridFilterOperator[] = [
      {
        label: "none of",
        value: "none of",
        getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
          if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
          }
      
          return (params): boolean => {
            const isEmptyCellSelected = filterItem.value.includes(''); 
            const isNullCellSelected = filterItem.value.includes(null);
      
            // Handle empty/null cells
            if (params.value === undefined || params.value === null || params.value === '') {
              // For "none of", exclude rows where empty or null are selected
              return !(isEmptyCellSelected || isNullCellSelected);
            }
      
            // Handle non-empty values for "none of"
            for (let attributeValue of filterItem.value) {
              if (params.value === attributeValue) {
                return false; // Exclude rows where the value matches any of the selected filter values
              }
            }
    
          // If no exclusions, include the row
          return true;
        };
      },
      InputComponent: LinearChannelDropdown,
    },
    {
      label: "any of",
      value: "any of",
      getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
    
        return (params): boolean => {
          const isEmptyCellSelected = filterItem.value.includes(''); 
          const isNullCellSelected = filterItem.value.includes(null);
    
          if (params.value === undefined || params.value === null || params.value === '') {
            return isEmptyCellSelected || isNullCellSelected; 
          }
    
          for (let attributeValue of filterItem.value) {
            if (params.value.split("; ").includes(attributeValue)) {
              return true;
            }
          }
    
          return false;
        };
      },
      InputComponent: LinearChannelDropdown,
    },  
    {
      label: "Is empty",
      value: "is empty",
      getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
        if (!filterItem.columnField || !filterItem.operatorValue) {
          return null;
        }
        return (params): boolean =>
          !params?.value?.length || params.value.length < 1;
      },
    },
    {
      label: "Is not empty",
      value: "is not empty",
      getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
        if (!filterItem.columnField || !filterItem.operatorValue) {
          return null;
        }
        return (params): boolean => params?.value?.length > 1;
      },
    },
  ];
  
  const SecondryChannelDropdown = (props: GridFilterInputValueProps) => {
    const secondryChannels = [
      "InsightTV",
      "LG",
      "Plex",
      "Roku",
      "Samsung AU",
      "STV",
      "Veely",
      "Vizio",
    ];
  
    const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
    const { item, applyValue } = props;
  
    const handleChange = (event: SelectChangeEvent<string[]>) => {
      const { target: { value } } = event;
      const selected = typeof value === 'string' ? value.split(',') : value;
      setSelectedChannels(selected);
      applyValue({ ...item, value: selected });
    };
  
    return (
      <Box sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center', height: 63, width: 180, pl: '20px' }}>
        <Select
          style={{ width: '100%' }}
          multiple
          value={selectedChannels}
          onChange={handleChange}
          renderValue={(selected) => (selected as string[]).join(', ')}
        >
          {secondryChannels.map((channel, index) => (
            <MenuItem key={index} value={channel}>
              {channel}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  const secoundryChannelSelectOperator: GridFilterOperator[] = [
    {
      label: "any of",
      value: "any of",
      getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }
  
        return (params): boolean => {
          const paramValue = params.value;
          if (!paramValue) return false;
  
          const secondryChannelArray = paramValue.split(';').map((item: string) => item.trim());
  
          return filterItem.value.some((selectedValue: string) => 
            secondryChannelArray.includes(selectedValue)
          );
        };
      },
      InputComponent: SecondryChannelDropdown,
    },
  ];

const attributeSelectorDefault: GridFilterOperator[] = [
  {
    label: "is blank",
    value: "is blank",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }
      //return (params): boolean => (!params?.value?.length || params.value.length === null)
      return (params) => !params?.value?.toString().trim();
    },
  },

  {
    label: "Is empty",
    value: "is empty",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }
      return (params): boolean =>
        !params?.value?.length || params.value.length < 1;
    },
  },
  {
    label: "Is not empty",
    value: "is not empty",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }
      return (params): boolean => params?.value?.length > 1;
    },
  },
];

const attributeSelectOperator: GridFilterOperator[] = [
  {
    label: "all of",
    value: "all of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params): boolean => {
        if (params.value === undefined || params.value === null) {
          // Keep rows with blank values
          return true;
        }

        // params is the row.column
        // if (!params.value) return filterItem.value.includes(''); //return false
        // attributeValue is a list of attributes inputted by the user
        for (let attributeValue of filterItem.value) {
          // will evaluate to false if the row does include one of the attributes
          if (params.value.split("; ").includes(attributeValue)) return true;
        }
        return false;
      };
    },
    InputComponent: AttributeInput,
  },
  {
    label: "none of",
    value: "none of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params): boolean => {
        if (params.value === undefined || params.value === null) {
          // Keep rows with blank values
          return false;
        }

        // params is the row.column
        // if (!params.value) return filterItem.value.includes(''); //return false
        // attributeValue is a list of attributes inputted by the user
        for (let attributeValue of filterItem.value) {
          // will evaluate to false if the row does include one of the attributes
          if (params.value.split("; ").includes(attributeValue)) return false;
        }
        return true;
      };
    },
    InputComponent: AttributeInput,
  },
  {
    label: "any of",
    value: "any of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params): boolean => {
        // params is the row.column
        if (!params.value) return false;
        // attributeValue is a list of attributes inputted by the user
        for (let attributeValue of filterItem.value) {
          // will evaluate to false if the row does not include one of the attributes
          if (params.value.split("; ").includes(attributeValue)) return true;
        }
        return false;
      };
    },
    InputComponent: AttributeInput,
  },
  ...attributeSelectorDefault,
];

const attributeHierarchySelectOperator: GridFilterOperator[] = [
  {
    label: "all of",
    value: "all of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      const {attributeTree} = column.filterOperatorProps;

      return (params): boolean => {
        // params is the row.column being filtered.
        if (!params.value) return false;

        // filterItem.value is an array of attributes that the user has inputted.
        for (let attributeValue of filterItem.value) {
          // we expand each user attribute into a list with parents, highest to lowest in the tree.
          const attributeListWithParents = attributeTree[attributeValue].parents
            ? [...attributeTree[attributeValue].parents, attributeValue]
            : [attributeValue];

          // evaluates to false if the row does not include one of the full list of attributes with parents.
          const hasAttribute = attributeListWithParents.some(
            (attribute: string) => params.value.split("; ").includes(attribute)
          );

          // if the row does not include one of the attributes, filter it out and move on.
          if (!hasAttribute) return false;
        }
        return true;
      };
    },
    InputComponent: AttributeInput,
  },
  {
    label: "none of",
    value: "none of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      const {attributeTree} = column.filterOperatorProps;

      return (params): boolean => {
        // params is the row.column being filtered.
        if (!params.value) return false;
        // filterItem.value is an array of attributes that the user has inputted.
        for (let attributeValue of filterItem.value) {
          // we expand each user attribute into a list with parents, lowest to highest in the tree.
          const attributeListWithParents = attributeTree[attributeValue].parents
            ? [
                ...attributeTree[attributeValue].parents,
                attributeValue,
              ].reverse()
            : [attributeValue];
          // evaluates to false if the row does not include one of the full list of attributes with parents.
          const hasAttribute = attributeListWithParents.some(
            (attribute: string) => params.value.split("; ").includes(attribute)
          );
          // if the row does not include one of the attributes, filter it out and move on.
          if (hasAttribute) return false;
        }
        return true;
      };
    },
    InputComponent: AttributeInput,
  },
  {
    label: "any of",
    value: "any of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      const {attributeTree} = column.filterOperatorProps;

      return (params): boolean => {
        // params is the row.column being filtered.
        if (!params.value) return false;
        // filterItem.value is an array of attributes that the user has inputted.
        for (let attributeValue of filterItem.value) {
          // we expand each user attribute into a list with parents, highest to lowest in the tree.
          const attributeListWithParents = attributeTree[attributeValue].parents
            ? [...attributeTree[attributeValue].parents, attributeValue]
            : [attributeValue];
          // evaluates to false if the row does not include one of the full list of attributes with parents.
          const hasAttribute = attributeListWithParents.some(
            (attribute: string) => params.value.split("; ").includes(attribute)
          );
          // if the row includes one of the attributes, pass the row and move on.
          if (hasAttribute) return true;
        }
        return false;
      };
    },
    InputComponent: AttributeInput,
  },
  {
    label: "has child of",
    value: "has child of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      const {attributeTree} = column.filterOperatorProps;

      return (params): boolean => {
        if (!params.value) return false;
        for (let attributeValue of filterItem.value) {
          const hasChild = params.value
            .split("; ")
            .some((attribute: string) => {
              if (attributeTree[attribute].parents?.includes(attributeValue))
                return true;
            });
          if (hasChild) return true;
        }
        return false;
      };
    },
    InputComponent: AttributeInput,
  },
  ...attributeSelectorDefault,
];

const DateRangeSelectOperator: GridFilterOperator[] = [
  {
    label: "between",
    value: "between",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      return ({value}) => {
        return (
          value !== null &&
          filterItem.value[0] <= value &&
          value <= filterItem.value[1]
        );
      };
    },
    InputComponent: DateRangeFilter,
  },
  {
    label: "before",
    value: "before",
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value == null) {
        return null;
      }

      return ({value}) => {
        return value !== null && value < filterItem.value;
      };
    },
    InputComponent: BeforeAfterFilter,
  },
  {
    label: "after",
    value: "after",
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value == null) {
        return null;
      }

      return ({value}) => {
        return value !== null && value > filterItem.value;
      };
    },
    InputComponent: BeforeAfterFilter,
  },
  {
    label: "past seven days",
    value: "pastSevenDays",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }

      const today = new Date();

      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(today.getDate() - 7);

      return (params) => {
        const dateValue = new Date(params?.value);
        return dateValue >= sevenDaysAgo && dateValue <= today;
      };
    },
  },
  {
    label: "from Today",
    value: "fromToday",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }

      const startOfToday = new Date();
      startOfToday.setHours(0, 0, 0, 0);

      return (params) => {
        const dateValue = new Date(params?.value);
        return dateValue >= startOfToday;
      };
    },
  },
  {
    label: "past thirty days",
    value: "pastThirtyDays",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }

      const today = new Date();

      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      return (params) => {
        const dateValue = new Date(params?.value);
        return dateValue >= thirtyDaysAgo && dateValue <= today;
      };
    },
  },

  ...attributeSelectorDefault,
];

const parseTime = (timeString: string): number => {
  const [hh, mm, ss, ms] = timeString.split(/[:.]/).map(Number);
  return hh * 3600 + mm * 60 + ss + ms / 1000;
};

const RangeSelectOperator: GridFilterOperator[] = [
  {
    label: "time range",
    value: "timeRange",
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value)) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      const [_minTime, _maxTime] = filterItem.value;
      const customLogScale = (value: any) => {
        value = Number(value);
        if (value === 0) return 0;
        if (value < 300) return value;
        if (value < 600) return (value - 300) * 2 + 300;
        if (value < 900) return (value - 600) * 9 + 900;
        if (value < 1200) return (value - 900) * 12 + 3600;
        return 3600000;
      };
      const minTime = customLogScale(_minTime), maxTime = customLogScale(_maxTime);


      return ({value}) => {
        if (value === null) {
          return false;
        }

        const entryTime = parseTime(value);

        return entryTime >= minTime && entryTime <= maxTime;
      };
    },
    InputComponent: RangeFilter,
  },
  ...attributeSelectorDefault,
];

export {
  secoundryChannelSelectOperator,
  linearChannelSelectOperator,
  attributeSelectOperator,
  attributeHierarchySelectOperator,
  DateRangeSelectOperator,
  RangeSelectOperator,
};
