import React, { MouseEvent, useState } from "react";
import { Switch, FormControlLabel, IconButton, ListItem, ListItemButton, Menu, Tooltip, Button, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import SaveIcon from "@mui/icons-material/Save";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Delete } from "@mui/icons-material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { PresetFilter } from "../pages/dataPage";
import { getGlobalColumns } from "./globalColumns"; 

interface PresetFiltersProps {
  updateFilterConfiguration: Function;
  presetFilterList: any[];
  filters: any[];
  openPresetFilterSaveDialogue: Function;
  loading: boolean;
  deleteFilterConfiguration: Function;
}

const PresetFilters = ({
  updateFilterConfiguration,
  presetFilterList,
  filters,
  openPresetFilterSaveDialogue,
  loading,
  deleteFilterConfiguration,
}: PresetFiltersProps) => {
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [modelName, setModelName] = useState<string>("");
  const [isPrivateUpdate, setIsPrivateUpdate] = useState<boolean>(true); // Default to private
  const gridApi = useGridApiContext();
  const apiRef = useGridApiContext();
  const { enqueueSnackbar } = useSnackbar();
  const selectPresetDialogueOpen = Boolean(anchorElement);

  const applyFilter = (model: any) => {
    const { filters, columns, pinnedColumns } = JSON.parse(model.filter_string);
    // Apply new filters
    setSelectedFilter(model.name);
    setModelName(model.name);
    gridApi.current.setFilterModel({ items: filters });

    // Apply column ordering and visibility
    const allColumns = gridApi.current.getAllColumns();
    allColumns?.forEach(({ field }: { field: string }) => {
      gridApi.current.setColumnVisibility(field, columns.includes(field));
      if (columns.includes(field)) {
        gridApi.current.setColumnIndex(field, columns.indexOf(field));
      }
    });

    // Apply pinned columns if any
    if (pinnedColumns) {
      gridApi.current.setPinnedColumns(pinnedColumns);
    }

    // Update the toggle switch based on the filter's private status
    setIsPrivateUpdate(model.private);
    enqueueSnackbar(`Filter "${model.name}" configuration applied!`, { variant: "success" });
    setAnchorElement(null);
  };

  const handleRemoveFilter = () => {
    // Step 1: Clear the model name and reset filter model
    setModelName(""); // Clear the model name state
    gridApi.current.setFilterModel({ items: [] }); // Reset the filter model (clear all filters)
  
    // Step 2: Restore column visibility and order to the original state
    const originalColumns = getGlobalColumns(); // Fetch the original column configuration
    if (originalColumns) {

      originalColumns.forEach((col) => {
        gridApi.current.setColumnVisibility(col.field, true); // Assume original visibility
        const columnOrder = originalColumns.map((column) => column.field); // Get original order
        gridApi.current.setColumnIndex(col.field, columnOrder.indexOf(col.field)); // Set the original column index
      });
    }
  
    // Step 3: Unpin all columns (clear pinned columns)
    gridApi.current.setPinnedColumns({}); // Unpin all columns

    // Notify user about the reset
    enqueueSnackbar("Filter removed, grid reset, and selection cleared!", { variant: "info" });
  };

  return (
    <Box>
      {/* Tooltip around the select preset filter button */}
      <Tooltip title={presetFilterList.length < 1 ? "No preset filters found" : "Select preset filter"}>
        <span>
          <IconButton
            onClick={(event: MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget)}
            disabled={presetFilterList.length < 1 || loading}
          >
            <ListAltIcon color={presetFilterList.length < 1 || loading ? "disabled" : "success"} />
          </IconButton>
        </span>
      </Tooltip>

      {/* Menu for selecting a preset filter */}
      <Menu
        style={{ maxHeight: 400 }}
        open={selectPresetDialogueOpen}
        anchorEl={anchorElement}
        onClose={() => setAnchorElement(null)}
      >
        {presetFilterList.map((presetFilter: PresetFilter) => (
          <ListItem
            disableGutters
            key={presetFilter.name}
            style={{
              backgroundColor: selectedFilter === presetFilter.name ? "lightblue" : "inherit",
            }}
            secondaryAction={
              <Tooltip title="Delete Filter">
                <IconButton onClick={() => deleteFilterConfiguration(presetFilter.name)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemButton onClick={() => applyFilter(presetFilter)}>
              {presetFilter.name}
            </ListItemButton>
          </ListItem>
        ))}
      </Menu>

      {/* Tooltip around the Save Filter Configuration button */}
      <Tooltip title={filters.length < 1 || loading ? "No configuration found" : "Save Filter Configuration"}>
        <span>
          <IconButton
            onClick={() => {
              const currentVisibleColumns = gridApi.current
                .getVisibleColumns()
                .map(({ field }: { field: string }) => field);
              openPresetFilterSaveDialogue({ columns: currentVisibleColumns });
            }}
            disabled={filters.length < 1 || loading}
          >
            <SaveIcon color={filters.length < 1 || loading || modelName ? "disabled" : "success"} />
          </IconButton>
        </span>
      </Tooltip>

      {/* Display current filter information and options */}
      {modelName && (
        <Box mt={2} sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
          <span>Current Filter: {modelName}</span>

          {/* Tooltip around Remove Filter button */}
          <Tooltip title="Remove Filter">
            <Button variant="contained" color="secondary" onClick={handleRemoveFilter} sx={{ ml: 2 }}>
              Remove Filter
            </Button>
          </Tooltip>

          {/* Tooltip around Update Filter button */}
          <Tooltip title="Update Filter">
            <Button
              variant="contained"
              color="primary"
              onClick={async () => await updateFilterConfiguration(modelName, isPrivateUpdate)}
              sx={{ ml: 2 }}
            >
              Update
            </Button>
          </Tooltip>

          {/* Switch to toggle public/private filter */}
          <FormControlLabel
            control={
              <Switch
                checked={!isPrivateUpdate}
                onChange={(e) => setIsPrivateUpdate(!e.target.checked)}
                name="publicToggle"
                color="primary"
                sx={{ ml: 2 }}
              />
            }
            label={isPrivateUpdate ? "Private" : "Public"}
          />
        </Box>
      )}
    </Box>
  );
};

export default PresetFilters;
