/**
 * This script manages the column configuration for a grid (likely a DataGridPro or similar component).
 * It ensures that a checkbox column is added at the first position of the columns array if it doesn't already exist.
 * 
 * The checkbox column serves two purposes:
 * 1. It allows individual row selection with checkboxes (`checkboxSelection`).
 * 2. It adds a "select all" checkbox in the header (`headerCheckboxSelection`).
 * 
 * The `setGlobalColumns` function checks if the checkbox column is already present. If not, it dynamically inserts
 * a new checkbox column at the beginning of the columns array. The checkbox column is configured to be non-sortable
 * and with a fixed width, and it disables the column menu to avoid accidental interactions.
 * 
 * The `getGlobalColumns` function returns the current global column configuration, including the checkbox column.
 */

let globalColumns: any[] = [];

/**
 * Sets the global column configuration. If a checkbox column doesn't already exist,
 * it inserts one at the beginning of the columns array.
 * 
 * @param {any[]} columns - The array of columns to be set globally.
 */
export const setGlobalColumns = (columns: any[]) => {
  const checkboxColumnExists = columns.some(col => col.checkboxSelection);
  if (!checkboxColumnExists) {
    const checkboxColumn = {
      field: '__check__',             
      headerCheckboxSelection: true, 
      checkboxSelection: true,       
      width: 50,                     
      sortable: false,               
      disableColumnMenu: true,       
    };
    columns.unshift(checkboxColumn);  
  }
  globalColumns = columns;
};

/**
 * Returns the current global column configuration, including the checkbox column if it exists.
 * 
 * @returns {any[]} - The current global columns array.
 */
export const getGlobalColumns = () => globalColumns;
