import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import {Button} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";

interface MiniDrawerProps {
  open: boolean;
  setOpen: Function;
  setDarkMode: Function;
  darkMode: boolean;
}

const MiniDrawer = ({
  setDarkMode,
  darkMode,
}: MiniDrawerProps) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem(`${process.env.REACT_APP_GCP_PROJECT}_access`);
    localStorage.removeItem(`${process.env.REACT_APP_GCP_PROJECT}_refresh`);
    navigate("/");
  };

  return (
    <Box sx={{display: "flex"}}>
      <MuiAppBar >
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{cursor: "default"}}
              variant="h6"
              noWrap
              component="div"
            >
              Baserock
            </Typography>
            <Box>
              <Switch
                color="secondary"
                onChange={() => setDarkMode((prevState: Boolean) => !prevState)}
                checked={darkMode}
              />
              <Button
                color="secondary"
                variant="contained"
                onClick={() => handleLogout()}
              >
                Log out
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </MuiAppBar>
    </Box>
  );
};

export default MiniDrawer;
